<!-- 新增楼栋 -->
<template>
  <div class="addld">
    <!-- 头部 -->
    <div class="header">
      <div class="span1" @click="callback">
        <img src="../../../../../../../assets/community/rollback.png" alt="" />
        <span>返回上层</span>
      </div>
      <div class="span2">
        <img
          src="../../../../../../../assets/community/locationSearch.png"
          alt=""
        />
        <!-- <span>华容区</span>
        <span>/</span>
        <span>柴电镇</span>
        <span>/</span> -->
        <span class="name">{{ this.gridName }}</span>
      </div>
    </div>
    <div class="container">
      <div class="top">
        <div class="imr1">
          <div class="oneimg">1</div>
          <span class="title1"
            >&nbsp;&nbsp;&nbsp;填写楼栋基本信息&nbsp;&nbsp;&nbsp;</span
          >
        </div>
        <div class="horizontal-line"></div>
        <div class="imr2">
          <div class="twoimg">2</div>
          <span class="title2"
            >&nbsp;&nbsp;&nbsp;编辑楼栋单元信息&nbsp;&nbsp;&nbsp;</span
          >
        </div>
      </div>
      <div class="conter">
        <!-- 基本信息 -->
        <div class="header-title">
          <ax-form
            ref="formBox"
            :formBuilder="formBuilder"
            @change="onFormChange"
          >
            <!-- 小区网格 -->
            <div slot="gridName" slot-scope="{ record }">
              <div v-if="level === '1' || !level">
                <div class="gridname" @click="showPopup">
                  <span
                    v-decorator="[
                      record.model,
                      {
                        rules: [
                          {
                            required: true,
                            message: '请选择小区',
                          },
                        ],
                      },
                    ]"
                    class="gridspan"
                    :style="{ display: name ? 'none' : 'block' }"
                  >
                    请选择小区
                  </span>
                  <span v-if="name">{{ name }}</span>
                </div>
                <el-dialog
                  title="选择小区"
                  width="800px"
                  :visible.sync="modalVisible"
                  :z-index="12"
                  :destroy-on-close="true"
                  :close-on-click-modal="false"
                >
                  <housing
                    ref="position"
                    @close="modalVisible = false"
                    @selection-change="handleSelectiononeChange"
                  />
                </el-dialog>
              </div>
              <div v-else>
                <div class="gridname2">
                  <span
                    v-decorator="['gridName', { rules: [{ required: true }] }]"
                    >{{ gridName }}</span
                  >
                </div>
              </div>
            </div>
            <!-- 地图标绘按钮 -->
            <div slot="position" style="display: flex">
              <ax-button type="#faad14" @click="dialogVisible = true"
                >地图标绘</ax-button
              >
              <el-dialog
                title="地图标绘"
                width="1000px"
                :visible.sync="dialogVisible"
                :z-index="12"
                :destroy-on-close="true"
                :close-on-click-modal="false"
              >
                <position
                  ref="position"
                  @close="dialogVisible = false"
                  @locationUpdated="handleLocationUpdate"
                />
              </el-dialog>
            </div>
            <!-- 楼栋经纬度 -->
            <div slot="trapeze" style="display: flex">
              <a-input-group>
                <a-row :gutter="24" style="display: flex; align-items: center">
                  <a-col :span="11">
                    <a-input
                      placeholder="请完成地图标绘"
                      v-model="latitude"
                      disabled
                    />
                  </a-col>
                  <a-col :span="2">
                    <div>-</div>
                  </a-col>
                  <a-col :span="11">
                    <a-input
                      placeholder="请完成地图标绘"
                      v-model="longitude"
                      disabled
                    />
                  </a-col>
                </a-row>
              </a-input-group>
            </div>
            <div class="title" slot="title1">&nbsp;基本信息</div>
            <div class="title" slot="title2">&nbsp;其他信息</div>
          </ax-form>
        </div>
      </div>
      <div class="footer">
        <div class="btn">
          <a-button>取消</a-button>
          <a-button type="primary" @click="submit"> 保存 </a-button>
          <a-button type="primary" @click="next"> 下一步 </a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../api";
import position from "../../Common/position.vue";
import housing from "../../Common/housing.vue";

const options = [
  { label: "单元楼", value: "0" },
  { label: "筒子楼", value: "1" },
  { label: "别墅", value: "2" },
  { label: "自建楼", value: "3" },
  { label: "平房", value: "4" },
  { label: "其他", value: "5" },
];
const formList = [
  {
    label: "",
    type: "",
    model: "title1",
    formItem: { colon: false },
    col: { span: 24 },
  },
  {
    label: "小区名称",
    type: "",
    model: "gridName",
    options: { placeholder: "山水西苑" },
    col: { span: 24 },
    formItem: { colon: false },
  },
  {
    label: "房屋类型",
    type: "radio",
    model: "houseType",
    options: { options },
    col: { span: 24 },
    rules: [
      {
        required: true,
        message: "请选择房屋类型",
      },
    ],
  },
  {
    label: "楼栋号",
    type: "",
    model: "blockCode",
    options: { placeholder: "请输入楼栋号", showTime: true },
    col: { span: 12 },
    rules: [
      {
        required: true,
        pattern: /^[1-9A-Za-z]\w{0,5}$/,
        message: "请输入正整数或字母",
      },
    ],
  },
  {
    label: "单元数",
    type: "",
    model: "unitNumber",
    options: { showTime: true, placeholder: "请输入单元数", maxLength: 3 },
    col: { span: 12 },
    rules: [
      {
        required: true,
        pattern: /^[1-9]\d{0,2}$/,
        message: "请输入正整数",
      },
    ],
  },
  {
    label: "",
    type: "",
    model: "title2",
    formItem: { colon: false },
    col: { span: 24 },
  },
  {
    label: "地图位置",
    type: "",
    model: "position",
    options: { showTime: true },
    col: { span: 12 },
  },
  {
    label: "楼栋经纬度",
    type: "",
    model: "trapeze",
    options: { showTime: true },
    col: { span: 12 },
  },
  {
    label: "楼长姓名",
    type: "",
    model: "managerName",
    options: { placeholder: "请输入", showTime: true, maxLength: 20 },
    col: { span: 12 },
    rules: [
      {
        required: false,
        message: "楼长姓名不能为空",
      },
    ],
  },
  {
    label: "楼长电话",
    type: "",
    model: "managerPhone",
    options: { showTime: true, placeholder: "请输入", maxLength: 11 },
    col: { span: 12 },
    rules: [
      {
        required: false,
        pattern: /^1[3-9]\d{9}$/,
        message: "请填写正确格式的电话号码",
      },
    ],
  },
];
export default {
  components: { position, housing },
  data() {
    return {
      api,
      gridName: "",
      longitude: "",
      latitude: "",
      name: "",
      cellId: "",
      qww: {},
      level: "",
      blockCode: "",
      unitNumber: "",
      customSelectionRows: [],
      modalVisible: false,
      dialogVisible: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 125 },
        { col: { span: 8 } }
      ),
    };
  },
  computed: {},
  created() {},
  methods: {
    //ax-from的change事件
    onFormChange(e, type) {
      if (type === "blockCode") {
        this.blockCode = e;
      } else if (type === "unitNumber") {
        this.unitNumber = e;
      }
    },
    //路由传值
    getSelection() {
      this.gridName = this.$route.query.gridName;
      this.$nextTick(() => {
        if (this.gridName === "平湖社区") {
          this.gridName = ""; // 清空gridName
          this.$refs.formBox.setFieldsValue({ gridName: "" }); // 清空表单中的值
        } else {
          this.$refs.formBox.setFieldsValue({ gridName: this.gridName }); // 设置gridName的值
        }
      });
      this.treeID = this.$route.query.treeID;
      this.level = this.$route.query.level;
      if (this.level === "1") {
        this.$refs.formBox.resetFields();
      }
    },
    //打开小区弹窗
    showPopup() {
      this.modalVisible = true; // 点击输入框时显示弹窗
    },
    //小区名称
    handleSelectiononeChange(selectionRows) {
      this.customSelectionRows = selectionRows;
      this.customSelectionRows.forEach((obj) => {
        if (obj.gridName) {
          this.name = obj.gridName;
          this.$nextTick(() => {
            this.$refs.formBox.setFieldsValue({ gridName: this.name });
          });
        }
        if (obj.id) {
          this.cellId = obj.id;
          console.log(this.cellId);
        }
      });
    },

    // 接收并处理传递过来的经纬度值
    handleLocationUpdate(locationData) {
      this.latitude = locationData.lng;
      this.longitude = locationData.lat;
    },
    //返回
    callback() {
      this.$router.push({
        path: "/views/communityGovernance/housingList/buildingList/Management",
      });
    },
    submit() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        console.log(value);
        if (err) return;
      });
    },
    //下一步
    next() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        value.cellId = this.cellId;
        this.$router.push({
          path: "/views/communityGovernance/housingList/buildingList/Management/components/edit.vue",
          query: {
            ...value, // 展开 value 对象中的所有键值对
          },
        });
      });
    },
  },
  mounted() {
    this.getSelection();
    this.$refs.formBox.resetFields();
  },
};
</script>
<style  lang="less" scoped>
.addld {
  width: 100%;
  height: 100%;
}
/deep/.el-dialog {
  border-radius: 20px !important;
}
.header {
  width: 100%;
  // height: 0.15rem;
  background: rgba(188, 204, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.span1 {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 1%;
  img {
    margin-right: 0.02rem;
  }
}
.span2 {
  display: flex;
  align-items: center;
  padding-right: 1.5%;
  img {
    margin-right: 0.01rem;
  }
  .name {
    color: #2b5dff;
  }
  span {
    font-weight: 700;
    font-size: 0.1rem;
    color: #2d3138;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-right: 0.01rem;
    white-space: nowrap;
  }
}
.container {
  margin-top: 2%;
}
.top {
  display: flex;
  align-items: center;
  justify-content: center;
}
.horizontal-line {
  width: 35%;
  border-top: 1px solid #909399;
  margin-left: 1%;
  margin-right: 1%;
}
.imr1 {
  display: flex;
  align-items: center;
  .title1 {
    font-weight: 700;
    font-size: 14px;
    color: #2d3138;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    white-space: nowrap;
  }
}
.imr2 {
  display: flex;
  align-items: center;
  .title2 {
    font-weight: 400;
    font-size: 14px;
    color: #909399;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    text-transform: none;

    white-space: nowrap;
  }
}
.oneimg {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.08rem;
  color: #ffffff;
  width: 0.2rem;
  height: 0.2rem;
  background-color: #2b5dff;
  border-radius: 50%; /* 使用 border-radius 来创建圆形 */
}
.twoimg {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.08rem;
  width: 0.2rem;
  height: 0.2rem;
  color: #909399;
  border: 1px solid #909399;
  border-radius: 50%; /* 使用 border-radius 来创建圆形 */
}
.conter {
  margin-top: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  flex-direction: column;
}
.footer {
  margin-top: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.btn {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.btn .ant-btn {
  margin: 0 10px; /* 在按钮的左右分别添加 10px 的间距 */
}
.title {
  border-left: 4px solid #556bff;
  font-size: 16px;
  font-weight: bold;
  color: black;
  line-height: 100%;
  margin-bottom: 15px;
}

.header-title {
  margin-bottom: 2%;
  width: 50%;
}
.gridname {
  position: relative;
  width: 100%;
  height: 32px;
  border: 1px solid #d9d9d9;
  text-align: left;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  .gridspan {
    position: absolute;
    left: 11px;
    color: #bfbfbf;
    pointer-events: none;
  }
}
.gridname2 {
  position: relative;
  width: 100%;
  height: 32px;
  border: 1px solid #d9d9d9;
  text-align: left;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
}
</style>